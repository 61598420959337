@font-face {
  font-family: 'Corisande-Regular';
  src: local('Corisande-Regular'), url(../fonts/Corisande-Regular.woff) format('woff'),
    url(../fonts/Corisande-Regular.eot) format('eot');
}
@font-face {
  font-family: 'Corisande-Bold';
  src: local('Corisande-Bold'), url(../fonts/Corisande-Bold.woff) format('woff'),
    url(../fonts/Corisande-Bold.eot) format('eot');
}
@font-face {
  font-family: 'Corisande-BoldItalic';
  src: local('Corisande-BoldItalic'), url(../fonts/Corisande-BoldItalic.woff) format('woff'),
    url(../fonts/Corisande-BoldItalic.eot) format('eot');
}
@font-face {
  font-family: 'Corisande-Italic';
  src: local('Corisande-Italic'), url(../fonts/Corisande-Italic.woff) format('woff'),
    url(../fonts/Corisande-Italic.eot) format('eot');
}
@font-face {
  font-family: 'Corisande-Light';
  src: local('Corisande-Light'), url(../fonts/Corisande-Light.woff) format('woff'),
    url(../fonts/Corisande-Light.eot) format('eot');
}
@font-face {
  font-family: 'Corisande-LightItalic';
  src: local('Corisande-LightItalic'), url(../fonts/Corisande-LightItalic.woff) format('woff'),
    url(../fonts/Corisande-LightItalic.eot) format('eot');
}

@font-face {
  font-family: 'Alegreya-Medium';
  src: local('Alegreya-Medium'), url(../fonts/Alegreya-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Alegreya-SemiBold';
  src: local('Alegreya-SemiBold'), url(../fonts/Alegreya-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Alegreya-Bold';
  src: local('Alegreya-Bold'), url(../fonts/Alegreya-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Alegreya-Black';
  src: local('Alegreya-Black'), url(../fonts/Alegreya-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'Alegreya-Sans-Regular';
  src: local('Alegreya-Sans-Regular'), url(../fonts/AlegreyaSans-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Alegreya-Sans-Medium';
  src: local('Alegreya-Sans-Medium'), url(../fonts/AlegreyaSans-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Alegreya-Sans-Bold';
  src: local('Alegreya-Sans-Bold'), url(../fonts/AlegreyaSans-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Alegreya-Sans-Black';
  src: local('Alegreya-Sans-Black'), url(../fonts/AlegreyaSans-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'Alegreya-Sans-Light';
  src: local('Alegreya-Sans-Light'), url(../fonts/AlegreyaSans-Light.ttf) format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Corisande-Regular', 'Helvetica Neue', sans-serif;
}

html,
body,
div#root {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-image: url(../images/background_grey.jpg);
}
